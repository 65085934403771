import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

// คอมโพเนนต์สำหรับแสดงข้อมูลแต่ละตำแหน่ง
const PositionNode = ({ name, title, children }) => {
  const { isDarkMode } = useTheme();
  return (
    <div className="flex flex-col items-center">
      <div className={`p-4 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-700' : 'bg-white'} mb-4`}>
        <h3 className="font-bold text-lg">{name}</h3>
        <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{title}</p>
      </div>
      {children && (
        <div className="flex flex-col items-center">
          <div className="w-px h-8 bg-gray-400"></div>
          <div className="flex flex-wrap justify-center">
            {React.Children.map(children, (child, index) => (
              <div key={index} className="mx-4">
                {index > 0 && <div className="w-8 h-px bg-gray-400 mb-4"></div>}
                {child}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const OrgStructure = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h1 className="text-3xl font-bold mb-8">โครงสร้างองค์กร</h1>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full">
          <PositionNode name="จอห์น โด" title="ซีอีโอ">
            <PositionNode name="เจน สมิธ" title="ผู้อำนวยการฝ่ายการเงิน">
              <PositionNode name="บ็อบ วิลสัน" title="ผู้จัดการบัญชี" />
              <PositionNode name="อลิซ จอห์นสัน" title="ผู้จัดการการเงิน" />
            </PositionNode>
            <PositionNode name="ไมค์ บราวน์" title="ผู้อำนวยการฝ่ายปฏิบัติการ">
              <PositionNode name="ซาร่า ลี" title="ผู้จัดการฝ่ายผลิต" />
              <PositionNode name="ทอม เดวิส" title="ผู้จัดการฝ่ายโลจิสติกส์" />
            </PositionNode>
            <PositionNode name="เอมิลี่ วอง" title="ผู้อำนวยการฝ่ายการตลาด">
              <PositionNode name="เดวิด คิม" title="ผู้จัดการฝ่ายดิจิทัลมาร์เก็ตติ้ง" />
              <PositionNode name="โซเฟีย โรดริเกซ" title="ผู้จัดการฝ่ายประชาสัมพันธ์" />
            </PositionNode>
          </PositionNode>
        </div>
      </div>
    </div>
  );
};

export default OrgStructure;