import React, { useState } from 'react';
import { Save, User, Lock, Bell } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const SettingsPage = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const [settings, setSettings] = useState({
    roles: [
      { id: 1, name: 'Admin', permissions: ['manage_users', 'manage_evaluationd', 'view_reports'] },
      { id: 2, name: 'Manager', permissions: ['manage_evaluations', 'view_reports'] },
      { id: 3, name: 'Employee', permissions: ['view_own_evaluations'] },
    ],
    notificationSettings: {
      emailNotifications: true,
      pushNotifications: false,
    },
    securitySettings: {
      twoFactorAuth: false,
      passwordExpiration: 90, // days
    },
  });

  const handleRoleChange = (roleId, newPermissions) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      roles: prevSettings.roles.map(role => 
        role.id === roleId ? { ...role, permissions: newPermissions } : role
      ),
    }));
  };

  const handleNotificationChange = (setting) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      notificationSettings: {
        ...prevSettings.notificationSettings,
        [setting]: !prevSettings.notificationSettings[setting],
      },
    }));
  };

  const handleSecurityChange = (setting, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      securitySettings: {
        ...prevSettings.securitySettings,
        [setting]: value,
      },
    }));
  };

  const handleSave = () => {
    // Here you would typically send the settings to your backend
    console.log('Saving settings:', settings);
    // Implement API call to save settings
  };

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h1 className="text-2xl font-bold mb-6">การตั้งค่าระบบ</h1>
      
      <div className={`mb-8 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow`}>
        <h2 className="text-xl font-semibold mb-4 flex items-center"><User className="mr-2" /> จัดการบทบาท (Roles)</h2>
        {settings.roles.map(role => (
          <div key={role.id} className="mb-4">
            <h3 className="font-semibold">{role.name}</h3>
            <div className="ml-4">
              {['manage_users', 'manage_evaluations', 'view_reports', 'view_own_evaluations'].map(perm => (
                <label key={perm} className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    checked={role.permissions.includes(perm)}
                    onChange={(e) => {
                      const newPermissions = e.target.checked
                        ? [...role.permissions, perm]
                        : role.permissions.filter(p => p !== perm);
                      handleRoleChange(role.id, newPermissions);
                    }}
                    className="mr-2"
                  />
                  {perm.replace(/_/g, ' ')}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={`mb-8 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow`}>
        <h2 className="text-xl font-semibold mb-4 flex items-center"><Bell className="mr-2" /> การแจ้งเตือน</h2>
        <label className="flex items-center mt-2">
          <input
            type="checkbox"
            checked={settings.notificationSettings.emailNotifications}
            onChange={() => handleNotificationChange('emailNotifications')}
            className="mr-2"
          />
          รับการแจ้งเตือนทางอีเมล
        </label>
        <label className="flex items-center mt-2">
          <input
            type="checkbox"
            checked={settings.notificationSettings.pushNotifications}
            onChange={() => handleNotificationChange('pushNotifications')}
            className="mr-2"
          />
          รับการแจ้งเตือนแบบ Push
        </label>
      </div>

      <div className={`mb-8 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow`}>
        <h2 className="text-xl font-semibold mb-4 flex items-center"><Lock className="mr-2" /> ความปลอดภัย</h2>
        <label className="flex items-center mt-2">
          <input
            type="checkbox"
            checked={settings.securitySettings.twoFactorAuth}
            onChange={(e) => handleSecurityChange('twoFactorAuth', e.target.checked)}
            className="mr-2"
          />
          เปิดใช้งานการยืนยันตัวตนแบบ 2 ชั้น
        </label>
        <div className="mt-4">
          <label className="block mb-2">ระยะเวลาหมดอายุของรหัสผ่าน (วัน)</label>
          <input
            type="number"
            value={settings.securitySettings.passwordExpiration}
            onChange={(e) => handleSecurityChange('passwordExpiration', parseInt(e.target.value))}
            className={`w-full p-2 rounded ${isDarkMode ? 'bg-gray-600 text-white' : 'bg-gray-100 text-gray-900'}`}
          />
        </div>
      </div>

      <div className={`mb-8 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow`}>
        <h2 className="text-xl font-semibold mb-4">การตั้งค่าทั่วไป</h2>
        <label className="flex items-center mt-2">
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={toggleTheme}
            className="mr-2"
          />
          โหมดมืด
        </label>
      </div>

      <button
        onClick={handleSave}
        className={`flex items-center justify-center px-4 py-2 rounded ${
          isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
        } text-white`}
      >
        <Save className="mr-2" /> บันทึกการตั้งค่า
      </button>
    </div>
  );
};

export default SettingsPage;