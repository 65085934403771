import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { Bell, Search, User, Menu, Sun, Moon, LogOut, Settings } from 'lucide-react';

const Header = ({ toggleSidebar }) => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user, logout } = useAuth();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex items-center">
          <button onClick={toggleSidebar} className="text-gray-500 dark:text-gray-200 lg:hidden">
            <Menu size={24} />
          </button>
          <div className="relative ml-4">
            <input
              type="text"
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
          </div>
        </div>
        <div className="flex items-center">
          <button onClick={toggleTheme} className="mr-4 text-gray-500 dark:text-gray-200">
            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
          <button className="mr-4 text-gray-500 dark:text-gray-200">
            <Bell size={24} />
          </button>
          <div className="relative">
            <button
              onClick={toggleProfileMenu}
              className="flex items-center text-gray-500 dark:text-gray-200 focus:outline-none"
            >
              <img
                src={user.profileImage || 'https://via.placeholder.com/40'}
                alt="Profile"
                className="w-8 h-8 rounded-full mr-2"
              />
              <span className="hidden md:block">{user.name}</span>
            </button>
            {isProfileMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md overflow-hidden shadow-xl z-10">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <User size={18} className="inline mr-2" />
                  Profile
                </Link>
                <Link
                  to="/settings"
                  className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <Settings size={18} className="inline mr-2" />
                  Settings
                </Link>
                <button
                  onClick={logout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <LogOut size={18} className="inline mr-2" />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;