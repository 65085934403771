import React, { useState } from 'react';
import { User, Search, Plus, ChevronDown } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const EmployeeInfo = () => {
  const [activeMenu, setActiveMenu] = useState('Dashboard');
  const [searchQuery, setSearchQuery] = useState('');
  const { isDarkMode } = useTheme();
  const [employees, setEmployees] = useState([
    { id: 1, name: 'จอห์น โด', position: 'โปรแกรมเมอร์', department: 'IT', status: 'ทำงาน' },
    { id: 2, name: 'เจน สมิธ', position: 'นักการตลาด', department: 'การตลาด', status: 'ลาพัก' },
  ]);

  const sideMenuItems = [
    'Dashboard', 'นำเข้าข้อมูลพนักงาน', 'รูปพนักงาน', 'ข้อมูลพื้นฐาน', 'ข้อมูลเงินเดือน',
    'ข้อมูลภูมิ', 'ข้อมูลบุคคล', 'กำหนดผู้บังคับ', 'ช่องทางการรับเงิน', 'ตั้งค่าการทำงาน', 'ตั้งค่าทำงาน-วันหยุด'
  ];

  const handleMenuClick = (menu) => setActiveMenu(menu);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const toggleTheme = () => useTheme;

  const renderContent = () => (
    <>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} p-4 rounded-lg`}>
          <h3 className="text-lg font-semibold mb-2">เพศ</h3>
          <div className="flex justify-around">
            <div className="text-center">
              <User size={48} className={`${isDarkMode ? 'text-blue-400' : 'text-blue-600'} mx-auto`} />
              <p className="text-3xl font-bold">1</p>
              <p>ชาย</p>
            </div>
            <div className="text-center">
              <User size={48} className={`${isDarkMode ? 'text-pink-400' : 'text-pink-600'} mx-auto`} />
              <p className="text-3xl font-bold">1</p>
              <p>หญิง</p>
            </div>
          </div>
        </div>
        <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} p-4 rounded-lg`}>
          <h3 className="text-lg font-semibold mb-2">ข้อมูลเพิ่มเติม</h3>
          <p>ข้อมูลอื่นๆ ที่เกี่ยวข้อง</p>
        </div>
      </div>
      <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} p-4 rounded-lg`}>
        <h3 className="text-lg font-semibold mb-2">รายชื่อพนักงาน</h3>
        <table className="w-full">
          <thead>
            <tr className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-300'}`}>
              <th className="p-2 text-left">ชื่อ</th>
              <th className="p-2 text-left">ตำแหน่ง</th>
              <th className="p-2 text-left">แผนก</th>
              <th className="p-2 text-left">สถานะ</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id} className={`border-t ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}>
                <td className="p-2">{employee.name}</td>
                <td className="p-2">{employee.position}</td>
                <td className="p-2">{employee.department}</td>
                <td className="p-2">{employee.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div className={`flex h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      {/* Main Content */}
      <div className="flex-1 flex">
        {/* Blue Sidebar */}
        <div className={`w-64 ${isDarkMode ? 'bg-blue-700' : 'bg-blue-500'} p-4`}>
          <h2 className="text-2xl font-bold mb-4 text-white">ข้อมูลพนักงาน</h2>
          <div className="space-y-2">
            {sideMenuItems.map((item, index) => (
              <button
                key={index}
                className={`w-full text-left py-2 px-4 rounded text-white ${
                  activeMenu === item
                    ? isDarkMode
                      ? 'bg-blue-800'
                      : 'bg-blue-600'
                    : 'hover:bg-blue-600'
                }`}
                onClick={() => handleMenuClick(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>

        {/* Content Area */}
        <div className={`flex-1 p-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} overflow-auto`}>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">ข้อมูลพนักงาน</h1>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="ค้นหา..."
                  className={`${
                    isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-900'
                  } px-4 py-2 rounded-full pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
              </div>
              <button className={`${isDarkMode ? 'bg-blue-600' : 'bg-blue-500'} text-white px-4 py-2 rounded-full hover:bg-blue-600 flex items-center`}>
                <Plus size={18} className="mr-2" />
                เพิ่มพนักงาน
              </button>
              <button className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} text-white px-4 py-2 rounded-full hover:bg-gray-600 flex items-center`}>
                เลือกพนักงาน
                <ChevronDown size={18} className="ml-2" />
              </button>
            </div>
          </div>

          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-lg shadow p-6`}>
            <h2 className="text-2xl font-bold mb-4">{activeMenu}</h2>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;