// src/components/evaluation/EvaluationFormList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getEvaluationForms, deleteEvaluationForm } from '../../services/api';
import { PlusCircle, Edit, Trash2 } from 'lucide-react';

const EvaluationFormList = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      setLoading(true);
      const response = await getEvaluationForms();
      setForms(response.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch evaluation forms');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this form?')) {
      try {
        await deleteEvaluationForm(id);
        setForms(forms.filter(form => form.id !== id));
      } catch (err) {
        setError('Failed to delete the form');
        console.error(err);
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">แบบฟอร์มการประเมิน</h1>
        <Link 
          to="/evaluation-forms/create" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <PlusCircle className="mr-2" size={20} />
          สร้างแบบฟอร์มใหม่
        </Link>
      </div>
      {forms.length === 0 ? (
        <p>ไม่มีแบบฟอร์มการประเมิน</p>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {forms.map(form => (
            <div key={form.id} className="bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-2">{form.title}</h2>
              <p className="text-gray-600 mb-4">{form.description}</p>
              <div className="flex justify-end space-x-2">
                <Link 
                  to={`/evaluation-forms/edit/${form.id}`}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Edit size={20} />
                </Link>
                <button 
                  onClick={() => handleDelete(form.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EvaluationFormList;