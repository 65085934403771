import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const StatCard = ({ icon: Icon, title, value, bgColor }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`${bgColor} rounded-lg shadow-lg p-6 flex items-center ${isDarkMode ? 'text-gray-200' : 'text-white'}`}>
      <div className={`rounded-full ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-3`}>
        <Icon size={24} className={isDarkMode ? 'text-gray-200' : 'text-gray-800'} />
      </div>
      <div className="ml-4">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-2xl font-bold">{value}</p>
      </div>
    </div>
  );
};

export default StatCard;