import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const NotificationCenter = () => {
  const { isDarkMode } = useTheme();

  const notifications = [
    { id: 1, message: 'New order received', time: '5 minutes ago' },
    { id: 2, message: 'Server update completed', time: '1 hour ago' },
    { id: 3, message: 'New user registered', time: '2 hours ago' },
  ];

  return (
    <div className={`${isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
      <h2 className="text-xl font-semibold mb-4">Notifications</h2>
      <ul className="divide-y divide-gray-200">
        {notifications.map((notification) => (
          <li key={notification.id} className="py-4">
            <div className="flex space-x-3">
              <div className="flex-1 space-y-1">
                <p className="text-sm font-medium">{notification.message}</p>
                <p className="text-sm text-gray-500">{notification.time}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationCenter;