import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const Home = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <header className="py-6">
        <div className="container mx-auto px-4">
          <nav className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Your Company</h1>
            <div>
              <Link to="/login" className="mr-4 hover:text-blue-500">Login</Link>
              <Link to="/register" className="hover:text-blue-500">Register</Link>
            </div>
          </nav>
        </div>
      </header>
      
      <main className="container mx-auto px-4 py-12">
        <section className="text-center">
          <h2 className="text-4xl font-bold mb-4">Welcome to Our Platform</h2>
          <p className="text-xl mb-8">Discover amazing features and boost your productivity.</p>
          <Link 
            to="/register" 
            className={`inline-block py-3 px-6 rounded-lg ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white font-semibold`}
          >
            Get Started
          </Link>
        </section>

        <section className="mt-20">
          <h3 className="text-2xl font-bold mb-6">Key Features</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
              <h4 className="text-xl font-semibold mb-2">Feature 1</h4>
              <p>Description of feature 1 and its benefits.</p>
            </div>
            <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
              <h4 className="text-xl font-semibold mb-2">Feature 2</h4>
              <p>Description of feature 2 and its benefits.</p>
            </div>
            <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
              <h4 className="text-xl font-semibold mb-2">Feature 3</h4>
              <p>Description of feature 3 and its benefits.</p>
            </div>
          </div>
        </section>
      </main>

      <footer className={`py-6 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Your Company. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;