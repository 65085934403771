// src/components/evaluation/EvaluationFormEditor.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createEvaluationForm, getEvaluationFormById, updateEvaluationForm } from '../../services/api';
import { PlusCircle, Minus, Save } from 'lucide-react';

const EvaluationFormEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    title: '',
    description: '',
    questions: [{ text: '', type: 'rating' }]
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (id) {
      fetchForm(id);
    }
  }, [id]);

  const fetchForm = async (formId) => {
    try {
      setLoading(true);
      const response = await getEvaluationFormById(formId);
      setForm(response.data);
    } catch (err) {
      setError('Failed to fetch the form');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...form.questions];
    updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
    setForm(prevForm => ({
      ...prevForm,
      questions: updatedQuestions
    }));
  };

  const addQuestion = () => {
    setForm(prevForm => ({
      ...prevForm,
      questions: [...prevForm.questions, { text: '', type: 'rating' }]
    }));
  };

  const removeQuestion = (index) => {
    setForm(prevForm => ({
      ...prevForm,
      questions: prevForm.questions.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        await updateEvaluationForm(id, form);
      } else {
        await createEvaluationForm(form);
      }
      navigate('/evaluation-forms');
    } catch (err) {
      setError('Failed to save the form');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">{id ? 'แก้ไขแบบฟอร์มการประเมิน' : 'สร้างแบบฟอร์มการประเมินใหม่'}</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">ชื่อแบบฟอร์ม</label>
          <input
            type="text"
            id="title"
            name="title"
            value={form.title}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">คำอธิบาย</label>
          <textarea
            id="description"
            name="description"
            value={form.description}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          ></textarea>
        </div>
        <div>
          <h2 className="text-lg font-medium text-gray-700 mb-2">คำถาม</h2>
          {form.questions.map((question, index) => (
            <div key={index} className="border border-gray-300 rounded-md p-4 mb-4">
              <div className="mb-2">
                <label htmlFor={`question-${index}`} className="block text-sm font-medium text-gray-700">คำถามที่ {index + 1}</label>
                <input
                  type="text"
                  id={`question-${index}`}
                  value={question.text}
                  onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label htmlFor={`question-type-${index}`} className="block text-sm font-medium text-gray-700">ประเภทคำถาม</label>
                <select
                  id={`question-type-${index}`}
                  value={question.type}
                  onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="rating">Rating</option>
                  <option value="text">Text</option>
                  <option value="multiple_choice">Multiple Choice</option>
                </select>
              </div>
              <button
                type="button"
                onClick={() => removeQuestion(index)}
                className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <Minus className="mr-2" size={16} />
                ลบคำถาม
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addQuestion}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusCircle className="mr-2" size={20} />
            เพิ่มคำถาม
          </button>
        </div>
        <div>
          <button
            type="submit"
            disabled={loading}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Save className="mr-2" size={20} />
            {id ? 'อัปเดตแบบฟอร์ม' : 'สร้างแบบฟอร์ม'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EvaluationFormEditor;