import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const PendingEvaluations = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [pendingEvaluations, setPendingEvaluations] = useState([]);

  useEffect(() => {
    // Fetch pending evaluations
    // This is a mock implementation. Replace with actual API call.
    const mockPendingEvaluations = [
      { id: 1, title: 'ประเมินประจำปี 2566', evaluatee: 'นายสมชาย ใจดี', dueDate: '2024-12-31' },
      { id: 2, title: 'ประเมินโครงการ A', evaluatee: 'นางสาวสมหญิง รักงาน', dueDate: '2024-11-30' },
      { id: 3, title: 'ประเมินทีม Marketing', evaluatee: 'ทีม Marketing', dueDate: '2024-10-31' },
    ];
    setPendingEvaluations(mockPendingEvaluations);
  }, []);

  const handleEvaluationClick = (id) => {
    // Navigate to the evaluation form
    navigate(`/evaluation/${id}`);
  };

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h1 className="text-2xl font-bold mb-6">การประเมินที่รอดำเนินการ</h1>
      {pendingEvaluations.length === 0 ? (
        <div className={`flex items-center justify-center p-4 ${isDarkMode ? 'bg-gray-700' : 'bg-white'} rounded-lg shadow`}>
          <CheckCircle className="text-green-500 mr-2" />
          <p>ไม่มีการประเมินที่รอดำเนินการในขณะนี้</p>
        </div>
      ) : (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {pendingEvaluations.map((evaluation) => (
            <div
              key={evaluation.id}
              className={`p-4 rounded-lg shadow cursor-pointer transition-transform transform hover:scale-105 ${
                isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-gray-50'
              }`}
              onClick={() => handleEvaluationClick(evaluation.id)}
            >
              <h2 className="text-xl font-semibold mb-2">{evaluation.title}</h2>
              <p className="mb-2">
                <span className="font-semibold">ผู้ถูกประเมิน:</span> {evaluation.evaluatee}
              </p>
              <div className="flex items-center text-sm">
                <AlertCircle className={`${isDarkMode ? 'text-yellow-300' : 'text-yellow-500'} mr-2`} size={16} />
                <span>กำหนดส่ง: {new Date(evaluation.dueDate).toLocaleDateString('th-TH')}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PendingEvaluations;