import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Bell, Search, User, Menu, Sun, Moon } from 'lucide-react';

const Header = ({ toggleSidebar }) => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md">
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex items-center">
          <button onClick={toggleSidebar} className="text-gray-500 dark:text-gray-200 lg:hidden">
            <Menu size={24} />
          </button>
          <div className="relative ml-4">
            <input
              type="text"
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
          </div>
        </div>
        <div className="flex items-center">
          <button onClick={toggleTheme} className="mr-4 text-gray-500 dark:text-gray-200">
            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
          <button className="mr-4 text-gray-500 dark:text-gray-200">
            <Bell size={24} />
          </button>
          <button className="text-gray-500 dark:text-gray-200">
            <User size={24} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;