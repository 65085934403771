import axios from 'axios';

const API_URL = 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const login = async (email, password) => {
  const response = await api.post('/users/login', { email, password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
  }
  return response.data;
};

export const register = async (userData) => {
  const response = await api.post('/users/register', userData);
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
  }
  return response.data;
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getProfile = async () => {
  return await api.get('/users/profile');
};

export const updateProfile = async (userData) => {
  return await api.put('/users/profile', userData);
};

export const createEvaluationForm = async (formData) => {
  return await api.post('/evaluation-forms', formData);
};

export const getEvaluationForms = async () => {
  return await api.get('/evaluation-forms');
};

export const getEvaluationFormById = async (id) => {
  return await api.get(`/evaluation-forms/${id}`);
};

export const updateEvaluationForm = async (id, formData) => {
  return await api.put(`/evaluation-forms/${id}`, formData);
};

export const deleteEvaluationForm = async (id) => {
  return await api.delete(`/evaluation-forms/${id}`);
};

export const createEvaluation = async (evaluationData) => {
  return await api.post('/evaluations', evaluationData);
};

export const submitEvaluation = async (evaluationData) => {
  return await api.post('/evaluations/submit', evaluationData);
};

export const getPendingEvaluations = async () => {
  return await api.get('/evaluations/pending');
};

export const getEvaluationById = async (id) => {
  return await api.get(`/evaluations/${id}`);
};

export default api;