import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const JobPositions = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h1 className="text-3xl font-bold mb-8">ตำแหน่งงาน</h1>
      {/* เพิ่มเนื้อหาสำหรับตำแหน่งงานที่นี่ */}
    </div>
  );
};

export default JobPositions;